// Zonarsystems colors

// primary

$light-primary: #0075b4;
$light-primary-contrast: #ffffff;

$dark-primary: #0075b4;
$dark-primary-contrast: #ffffff;

// accent/secondary

$light-accent: #0075b4;
$light-accent-contrast: #ffffff;

$dark-accent: #0075b4;
$dark-accent-contrast: #212121;

// common

$page-background: #e8ebed;
